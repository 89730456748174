<template>
  <div class="default-tab">
    <ul class="nav nav-tabs" role="tablist">
      <li
        class="nav-item"
        v-for="(group, key, index) in exercises_groups"
        :key="index"
      >
        <a
          class="nav-link"
          :class="index < 1 ? 'active' : ''"
          data-toggle="tab"
          :href="`#group_${indexCard}_${key}_${getSectionKey()}`"
        >
          <i class="la la-home mr-2"></i>
          Bloque {{ key }}
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div
        class="tab-pane fade"
        :class="index < 1 ? 'show active' : ''"
        :id="`group_${indexCard}_${key}_${getSectionKey()}`"
        role="tabpanel"
        v-for="(group, key, index) in exercises_groups"
        :key="index"
      >
        <div class="mt-4" v-for="(exercise, i) in group" :key="i">
          <div class="pb-2" v-if="i == 0 && exercise.pivot.repeticiones_grupo">
            <h5>
              <span class="badge badge-secondary" style="width: 100%">
                Series por ejercicio:
                {{ exercise.pivot.repeticiones_grupo }}
              </span>
            </h5>
          </div>
          <routines-exercises-consult :exercise="exercise" :color="color"></routines-exercises-consult>
          <div>
            <span
              class="badge mr-3 rounded bg-secondary"
              style="color: white"
              v-show="exercise.pivot.repetitions"
            >
              <b>Repeticiones:</b>
              {{ exercise.pivot.repetitions }}
            </span>

            <span
              class="badge mr-3 rounded bg-warning"
              style="color: white"
              v-show="exercise.pivot.weight"
            >
              <b>Peso:</b>
              {{ exercise.pivot.weight }}
            </span>

            <span
              class="badge mr-3 rounded bg-info"
              style="color: white"
              v-show="exercise.pivot.time"
            >
              <b>Tiempo:</b>
              {{ exercise.pivot.time }}
            </span>

            <span
              class="badge mr-3 rounded bg-secondary"
              style="color: white"
              v-show="exercise.pivot.rest"
            >
              <b>Descanso:</b>
              {{ exercise.pivot.rest }}
            </span>

            <div class="mt-2 p-1" v-show="exercise.pivot.comment">
              <b>Comentarios:</b>
              {{ exercise.pivot.comment }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import RoutinesExercisesConsult from './RoutinesExercisesConsult.vue';
export default {
  components: { RoutinesExercisesConsult },
  props: {
    exercises_groups: {
      type: Object,
    },
    indexCard: [Number, String],
    section_title: String,
    color: String,
  },
  setup(props) {
    const selectedExercise = ref(null);
    const getSectionKey = () => {
      
      var titleSplit = props.section_title.split(/[\s,.-]+/);
      
      return titleSplit.at(-1);
    };
    return {
      getSectionKey,
      selectedExercise,
    };
  },
};
</script>

<style scoped>
</style>
