<template>
    <div>
        <bread-layout :items="breadcrumbLinks"></bread-layout>
        <div class="row">
            <template v-if="!loading">
                <div class="col-md-12">
                    <div class="card" style="padding:10px;">
                        <div class="card-header">
                            <h4>Mis entrenamientos</h4>
                        </div>
                        <routines-accordion :routine_types="getParsedRoutines"></routines-accordion>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue-demi'
import BreadLayout from '../../layouts/BreadLayout.vue'
import useGetRoutines from "../../composables/routines/useGetRoutines.js";
import RoutinesCard from './RoutinesCard.vue';
import RoutinesAccordion from './RoutinesAccordion.vue';
import { computed, onMounted } from '@vue/runtime-core';
export default {
    components: {
        BreadLayout,
        RoutinesCard,
        RoutinesAccordion
    },
    setup() {
        onMounted(()=>{
           window.scrollTo( 0, 0 );
        })
        const breadcrumbLinks = ref([
            {
                text: "Entrenamientos",
                path: "/rutinas",
            },
        ])

        const {
            loading,
            getRoutines,
            data: routines,
        } = useGetRoutines();

        getRoutines();

        const getParsedRoutines = computed(() => {
            return {
                "Calentamiento": {
                    routines: routines.value?.rutinas_base[0],
                    color: 'primary'
                },
                "Entrenamientos activos": {
                    routines: routines.value?.client_routines,
                    color: 'info'
                },
                "Estiramiento": {
                    routines: routines.value?.rutinas_base[1],
                    color: 'success'
                },
                "Entrenamientos terminados": {
                    routines: routines.value?.client_routines_history,
                    color: 'primary'
                },
            }
        })

        return {
            breadcrumbLinks,
            loading,
            routines,
            getParsedRoutines
        }
    }
}
</script>

<style>
</style>
