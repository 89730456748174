<template>
    <a
        href="#"
        :data-target="`#modalImg_id_${exercise.id}`"
        data-toggle="modal"
        @click="isExerciseSelected = true"
    >
        <h4>
            <span
                :class="`badge badge-${color}`"
                style="white-space: break-spaces; width: 100%"
            >{{ exercise.name }}</span>
        </h4>
    </a>

    <teleport to="#modal-wrapper">
        <c-modal
            :id="`modalImg_id_${exercise.id}`"
            :title_modal="exercise.name"
            readOnly
            v-if="isExerciseSelected"
        >
            <template #modalBody>
                <div class="col-md-12">
                    <h5 class="text-primary d-inline">Imagenes del ejercicio</h5>
                    <div class="form-group mt-2">
                        <div class="bootstrap-carousel">
                            <div
                                :id="`carousel_id_${exercise.id}`"
                                class="carousel slide"
                                data-ride="carousel"
                            >
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img
                                            class="d-block w-100"
                                            :src="`${base_url_api}/storage/exercises/${exercise.cover_path}`"
                                            :alt="`Imagen de ejercicio ${exercise.name}`"
                                        />
                                    </div>

                                    <div
                                        class="carousel-item"
                                        v-for="image in exercise.images"
                                        :key="image.id"
                                    >
                                        <img
                                            :src="`${base_url_api}/storage/exercises/${image.path}`"
                                            class="view-box"
                                        />
                                    </div>
                                </div>
                                <a
                                    class="carousel-control-prev"
                                    :href="`#carousel_id_${exercise.id}`"
                                    data-slide="prev"
                                >
                                    <span class="carousel-control-prev-icon"></span>
                                    <span class="sr-only">Previous</span>
                                </a>
                                <a
                                    class="carousel-control-next"
                                    :href="`#carousel_id_${exercise.id}`"
                                    data-slide="next"
                                >
                                    <span class="carousel-control-next-icon"></span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <h5 class="text-primary d-inline">Vídeo del ejercicio</h5>
                    <div class="form-group mt-2">
                        <template v-if="exercise.embed_video_url">
                            <iframe
                                :src="exercise.embed_video_url"
                                frameborder="0"
                                width="100%"
                                height="500px"
                            ></iframe>
                        </template>
                        <template v-else>
                            <label>Video no disponible.</label>
                        </template>
                    </div>
                </div>
            </template>
        </c-modal>
    </teleport>
</template>

<script>
import { ref } from 'vue-demi'
import { computed } from 'vue-demi'
import CModal from '../../components/modals/CModal.vue'
import { PhotoProvider, PhotoConsumer } from "vue3-photo-preview";
import "vue3-photo-preview/dist/index.css";
export default {
    components: { CModal, PhotoProvider, PhotoConsumer },
    props: {
        exercise: Object,
        color: String
    },
    setup() {
        return {
            isExerciseSelected: ref(false),
            base_url_api: computed(() => process.env.VUE_APP_API_GATEWAY),
        }
    }

}
</script>

<style scoped>
.view-box {
  width: 100%;
}
</style>
