<template>
    <div class="col-md-12">
        <div class="card-body" style="padding:0;">
            <div id="accordion-routine" class="accordion accordion-header-bg accordion-bordered">
                <div
                    class="accordion__item"
                    v-for="(routine, index) in routine_type.routines"
                    :key="index"
                >
                    <div
                        :class="`accordion__header accordion__header--${routine_type.color} collapsed`"
                        data-toggle="collapse"
                        :data-target="`#active-header_collapse${index}_${routine.id}`"
                    >
                        <span class="accordion__header--icon"></span>
                        <span
                            class="accordion__header--text text-uppercase"
                        >{{ routine.name || routine?.routine?.name || 'Rutina personalizada' }}</span>
                        <span class="accordion__header--indicator"></span>
                    </div>
                    <div
                        :id="`active-header_collapse${index}_${routine.id}`"
                        class="collapse accordion__body"
                        data-parent="#accordion-routine"
                    >
                        <div class="accordion__body--text px-0">
                            <routines-card
                                :section_title="routine.name || routine?.routine?.name || 'Rutina personalizada'"
                                :routine="routine"
                                :indexCard="index"
                                :color="routine_type.color"
                            ></routines-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RoutinesCard from './RoutinesCard.vue'
export default {
    components: { RoutinesCard },
    props: {
        routine_type: Object
    },
}
</script>

<style>
</style>
