<template>
    <div class="card">
        <div class="card-body" >
            <div class="row m-b-30">
                <div class="col-md-12">
                    <div class="new-arrival-content position-relative">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4>
                                <a
                                    href="#"
                                    class="text-black"
                                >{{ routine?.name ?? 'Rutina personalizada' }}</a>
                            </h4>
                        </div>
                        <p
                            class="price"
                            :class="`text-${color}`"
                        >{{ routine?.routine_type?.name ?? 'Personalizado' }}</p>
                        <div class="row">
                            <template v-if="routine.exercises_groups">
                                <routines-exercises
                                    :exercises_groups="routine.exercises_groups"
                                    :indexCard="indexCard"
                                    :section_title="section_title"
                                    :color="color"
                                ></routines-exercises>
                            </template>
                            <template v-else>
                                <span
                                    :class="`badge badge-${checkStatus(routine.status).color}`"
                                >{{ checkStatus(routine.status).text }}</span>
                                <p class="text-content">Descripción: {{ routine.description }}</p>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RoutinesExercises from './RoutinesExercises.vue'
import useStatus from '../../composables/useStatus'
import { reactive } from 'vue-demi'
export default {
    components: { RoutinesExercises },
    props: {
        routine: {
            type: Object
        },
        indexCard: [Number, String],
        section_title: {
            type: [String, Boolean],
            default: false
        },
        color: String
    },
    setup(props) {
        const routine = reactive({})

        // parsea los objetos para que sean iguales
        Object.keys(props.routine).map(r => {
            if (r == 'routine') {
                Object.assign(routine, { ...props.routine[r] })
            } else {
                routine[r] = props.routine[r]
            }
        })

        const { checkStatus } = useStatus()

        return {
            checkStatus,
            routine
        }
    }
}
</script>

<style>
</style>
