<template>
    <div class="col-md-12">
        <div class="card-body" style="padding: 10px;">
            <div
                id="accordion-routine-type"
                class="accordion accordion-header-bg accordion-bordered"
            >
                <div
                    class="accordion__item"
                    v-for="(routine_type, key, index) in routine_types"
                    :key="index"
                >
                    <div
                        :class="`accordion__header accordion__header--${routine_type.color} collapsed`"
                        data-toggle="collapse"
                        :data-target="`#active-header_collapse${index}`"
                    >
                        <span class="accordion__header--icon"></span>
                        <span class="accordion__header--text text-uppercase">{{ key }}</span>
                        <span class="accordion__header--indicator"></span>
                    </div>
                    <div
                        :id="`active-header_collapse${index}`"
                        class="collapse accordion__body"
                        data-parent="#accordion-routine-type"
                    >
                        <div class="accordion__body--text px-0">
                            <template v-if="Array.isArray(routine_type.routines)">
                                <routines-exercises-accordion :routine_type="routine_type"></routines-exercises-accordion>
                            </template>
                            <template v-else>
                                <routines-card
                                    :section_title="key"
                                    :routine="routine_type.routines"
                                    :indexCard="0"
                                    :color="routine_type.color"
                                ></routines-card>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RoutinesCard from './RoutinesCard.vue'
import RoutinesExercisesAccordion from './RoutinesExercisesAccordion.vue'
export default {
    components: { RoutinesCard, RoutinesExercisesAccordion },
    props: {
        routine_types: Object
    },
    setup() {

    }
}
</script>

<style>
</style>
