import peticionHttp from "../peticionHttp";
const service = "api";

export const getRoutineListService = async () => {
    const response = await peticionHttp.get(`${service}/client-routines`);
    return response;
};
export const getRoutineService = async (id) => {
    const response = await peticionHttp.get(`${service}/client-routines`, id);
    return response;
};
